import React from 'react';
import './Contact.css'; // Assurez-vous que le chemin d'accès est correct

const Contact = () => {
  const handleGetDirections = () => {
    window.open('https://www.google.com/maps/dir/?api=1&destination=Place+du+G%C3%A9n%C3%A9ral+Leclerc,+94120+Fontenay-sous-Bois', '_blank');
  };

  return (
    <div>
      <h1 className="ContactTitle">Contact</h1>
      <div className="ContactContent">
        <br />
        <p>Pour toute question, réservation (par téléphone ou sur place uniquement) ou information supplémentaire, n'hésitez pas à contacter Le Village Pub Restaurant.</p>
        <p><strong>Adresse :</strong> Place du Général Leclerc, 94120 Fontenay-sous-Bois</p>
        <p><strong>Téléphone :</strong> 01 79 61 08 76</p>
        <p>Suivez-nous également sur <a href="https://www.instagram.com/pub_brasserie_le_village/" target="_blank" rel="noopener noreferrer">Instagram</a> & <a href="https://www.facebook.com/pubbrasserielevillage/" target="_blank" rel="noopener noreferrer">Facebook</a></p>
        <br />
        <button className="GetDirectionsButton" onClick={handleGetDirections}>S'y rendre</button>
        <br />
        <div className="Signature">
          Site conçu par Monsieur Emmanuel Baudrier SIRET 923434252
        </div>
      </div>
    </div>
  );
};

export default Contact;
