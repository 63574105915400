import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header/Header';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Pub-Brasserie Le Village à Fontenay sous-bois</title>
        <meta name="description" content="En plein cœur de Fontenay sous-bois, le Pub-Brasserie Le Village, créé en 2005 par deux amis, vous invite à découvrir son ambiance unique avec une décoration boisée, un bar lumineux et une grande terrasse avec zone non-fumeur. Nous sommes ouverts du mardi au dimanche de 10h à minuit." />
      </Helmet>
      <Header />
      {/* Autres composants ou contenu ici */}
    </div>
  );
}

export default App;
