import React, { useState } from 'react';
import './MenuHamburger.css';
import AboutUs from '../Pages/AboutUs';
import OurMenu from '../Pages/OurMenu';
import Gallery from '../Pages/Gallery';
import Contact from '../Pages/Contact';
import Header from '../Header/Header';

// La prop 'onNavigate' doit être passée ici
const MenuHamburger = ({ onNavigate }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('home'); // 'home' comme valeur par défaut si c'est la page par défaut

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (page) => {
    setCurrentPage(page);
    setMenuOpen(false);
    // Appeler 'onNavigate' avec la nouvelle page. Si c'est 'home', onNavigate(true) va montrer le logo et le titre.
    onNavigate(page === 'home');
  };

  const renderPageComponent = () => {
    switch (currentPage) {
      case 'header':
        return <Header />;
      case 'about-us':
        return <AboutUs />;
      case 'our-menu':
        return <OurMenu />;
      case 'gallery':
        return <Gallery />;
      case 'contact':
        return <Contact />;
      
      default:
        return null; // ou <HomePage /> si vous voulez que la page d'accueil soit le fallback
    }
  };

  return (
    <div className="menu-hamburger-container">
      <button className="hamburger-icon" onClick={toggleMenu}>
        ☰
      </button>
      {menuOpen && (
        <div className="menu-content">
          <ul>
          <li onClick={() => handleMenuItemClick('header')}>Accueil</li>
            <li onClick={() => handleMenuItemClick('about-us')}>À propos</li>
            <li onClick={() => handleMenuItemClick('our-menu')}>Le Menu</li>
            <li onClick={() => handleMenuItemClick('gallery')}>Galerie</li>
            <li onClick={() => handleMenuItemClick('contact')}>Contact</li>
          </ul>
        </div>
      )}
      {renderPageComponent()}
    </div>
  );
};

export default MenuHamburger;
