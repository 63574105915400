import React, { useState, useRef } from 'react';
import './OurMenu.css'; // Import the CSS file

const OurMenu = () => {
  const categories = {
    drinks: [
      'Biere.png',
      'Cocktails2.png',
      'LesCoupesCoktails.png',
      'La cave.png',
      'Cafe.png',
    ],
    cuisine: [
      'Finger Food.png',
      'LesBurgers.png',
      'Salades Gourmandes.png',
      'LesOmelettesetTagliatelles.jpg',
      'LesCharcuteries.jpg',
      'Tartares.png',
      'Glaces et Sorbets.png',
      'LesFromagesLesDesserts.png',
      'LesGlacesEtSorbets.jpg',
      'KidsCorner.jpg',
    ],
    information: [
      'Informations.png',
      'Infos2.png',
    ],
  };

  const toggleFullScreen = () => {
    if (imageRef.current) {
      const img = imageRef.current;
      if (img.requestFullscreen) { 
        img.requestFullscreen();
      } else if (img.webkitRequestFullscreen) { 
        img.webkitRequestFullscreen();
      } else if (img.msRequestFullscreen) { 
        img.msRequestFullscreen();
      } else if (img.mozRequestFullScreen) { 
        img.mozRequestFullScreen();
      }
    }
  };

  const [currentCategory, setCurrentCategory] = useState('drinks');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const imageRef = useRef(null);

  const nextPage = () => {
    setCurrentPageIndex(prevIndex => (prevIndex + 1) % categories[currentCategory].length);
  };

  const previousPage = () => {
    setCurrentPageIndex(prevIndex => (prevIndex - 1 + categories[currentCategory].length) % categories[currentCategory].length);
  };

  const changeCategory = (category) => {
    setCurrentCategory(category);
    setCurrentPageIndex(0);
  };

  return (
    <div className="menuContainer">
      <h2 className="menuTitle">Le Menu</h2>
      <img
        ref={imageRef}
        src={`${process.env.PUBLIC_URL}/${categories[currentCategory][currentPageIndex]}`}
        alt={`Page ${currentPageIndex + 1}`}
        className="imageStyle"
        onClick={toggleFullScreen}
      />
      <div className={`navigationStyle ${currentPageIndex === 0 || currentPageIndex === categories[currentCategory].length - 1 ? 'center' : ''}`}>
        {currentPageIndex > 0 && (
          <button className="buttonStyle" onClick={previousPage}>←</button>
        )}
        <select
          onChange={(e) => changeCategory(e.target.value)}
          value={currentCategory}
          className="buttonStyle"
          style={{ padding: '10px', margin: '0 20px' }}
        >
          <option value="drinks">Boissons</option>
          <option value="cuisine">Cuisine</option>
          <option value="information">Informations</option>
        </select>
        {currentPageIndex < categories[currentCategory].length - 1 && (
          <button className="buttonStyle" onClick={nextPage}>→</button>
        )}
      </div>
    </div>
  );
};

export default OurMenu;
