import React, { useState, useRef, useEffect } from 'react';
import MenuHamburger from '../MenuHamburger/MenuHamburger';
import './Header.css';

function Header() {
  const [showLogoAndTitle, setShowLogoAndTitle] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const logoAnimationUrl = `${process.env.PUBLIC_URL}/LogoAnimation.mp4`;


  const handleVideoEnd = () => {
    setVideoLoaded(false);
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      const nextMutedState = !video.muted;
      video.muted = nextMutedState;
      setIsMuted(nextMutedState);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleCanPlayThrough = () => {
      setVideoLoaded(true); // La vidéo est chargée et prête à être jouée
      video.play().catch(error => console.log('Erreur lors de la lecture de la vidéo:', error));
    };

    video.addEventListener('canplaythrough', handleCanPlayThrough);

    // Déclenchez explicitement le chargement de la vidéo
    video.load();

    return () => {
      video.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, []);

  // Assurez-vous que `showLogoAndTitle` est contrôlé indépendamment de la logique de sourdine
  // Par exemple, en utilisant des props ou un contexte global pour gérer son état si nécessaire

  return (
    <div className="full-page">
      <MenuHamburger onNavigate={(page) => setShowLogoAndTitle(page === 'home')} />
      {showLogoAndTitle && (
        <div className="centered-logo">
          <div className="video-container">
            <video
              ref={videoRef}
              autoPlay
              preload="auto"
              muted={isMuted}
              className="logo"
              onEnded={handleVideoEnd}
            >
              <source src={logoAnimationUrl} type="video/mp4" />
              Votre navigateur ne prend pas en charge les vidéos HTML5.
            </video>
            {videoLoaded && (
              <span className="mute-icon" onClick={toggleMute}>
                {isMuted ? '🔇' : '🔊'}
              </span>
            )}
          </div>
          <h1 className="HomePageTitle" style={{ fontSize: '3rem', color: '#cca500' }}>Pub Brasserie Restaurant</h1>
        </div>
      )}
    </div>
  );
}

export default Header;
