import React, { useState, useRef } from 'react';
import './Gallery.css';

const Gallery = () => {
  const imageCategories = [
    {
      title: 'Extérieur du restaurant',
      images: ['OutsideRestaurantCorner.jpeg', 'OutsideFrontFromOutside.jpeg'],
    },
    {
      title: 'Intérieur du bar',
      images: [
        'InsideBarWideView.jpeg',
        'InsideBarWideView2.jpeg',
        'InsideBarWideView3.jpeg',
        'InsideBarWideView4.jpeg',
      ],
    },
  ];

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imageRef = useRef(null);

  const toggleFullScreen = () => {
    if (imageRef.current.requestFullscreen) {
      imageRef.current.requestFullscreen();
    } else if (imageRef.current.mozRequestFullScreen) {
      imageRef.current.mozRequestFullScreen();
    } else if (imageRef.current.webkitRequestFullscreen) {
      imageRef.current.webkitRequestFullscreen();
    } else if (imageRef.current.msRequestFullscreen) {
      imageRef.current.msRequestFullscreen();
    }
  };
  


  const nextImage = () => {
    const category = imageCategories[currentCategoryIndex];
    if (currentImageIndex < category.images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else if (currentCategoryIndex < imageCategories.length - 1) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
      setCurrentImageIndex(0);
    } else {
      // Si c'est la dernière image de la dernière catégorie, ne fait rien ou réinitialise les index si nécessaire
      return;
    }
    ;
  };

  const previousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else if (currentCategoryIndex > 0) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
      const newCategory = imageCategories[currentCategoryIndex - 1];
      setCurrentImageIndex(newCategory.images.length - 1);
    } else {
      // Si c'est la première image de la première catégorie, ne fait rien ou réinitialise les index si nécessaire
      return;
    }
    ;
  };

  const currentCategory = imageCategories[currentCategoryIndex];
  const currentImage = currentCategory.images[currentImageIndex];

  const isLastImage = currentImageIndex === imageCategories[currentCategoryIndex].images.length - 1;


  return (
    <div className="gallery-container">
      <h1 className="gallery-title">Galerie</h1>
      <div className="image-item">
        <img
          ref={imageRef} // Supposons que vous avez une référence à l'image pour le mode plein écran
          src={`${process.env.PUBLIC_URL}/${currentImage}`}
          alt={currentCategory.title}
          className="gallery-image" // Assurez-vous que cette classe est définie correctement dans votre CSS
          onClick={toggleFullScreen} // Si vous avez une fonction pour basculer en mode plein écran
        />
      </div>
      <div className="navigation-buttons">
        <button onClick={previousImage} className="navigation-button">
          ←
        </button>
        <button onClick={nextImage} className="navigation-button">
          →
        </button>
      </div>
    </div>
  );
};

export default Gallery;
