import React from 'react';
import './AboutUs.css'; // Assurez-vous que le chemin d'importation est correct

const AboutUs = () => {
  return (
    <div className="content">
      <h2 className="AboutUsTitle">À propos</h2>
      <p>
        Crée par 2 amis de longue date, Pub-Brasserie Le Village est situé en plein cœur de l'ancien village de Fontenay sous-bois, lieu incontournable depuis son ouverture en 2005.
      </p>
      <p>
      </p>
      <p>
        Décoration atypique composée de mobiliers boisés avec un grand bar lumineux et mur en briques rouges. <p></p> Le Pub est également doté d'une grande terrasse, avec une zone non-fumeur ! <p></p> Notre ambiance musicale "Rock Old school", Hard Rock, et 80's vous attend. <p></p> Pour les amateurs de sport, nous diffusons les compétitions du moment (foot, rugby, tennis, ski...) sur nos nombreux écrans.<p>Parcourez par vous même le reste de ce site pour découvrir notre menu complet, notre gallerie d'images et notre adresse pour nous trouver   !</p>
      </p>

      <p>
      </p>
    </div>
  );
};

export default AboutUs;
